import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CustomTextField from 'common/MuiCustomTextField'
import getById from 'utils/getById'
import getFullName from 'utils/getFullName'
import setPhoneMask from 'utils/setPhoneMask'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex'
  },
  container: {
    position: 'relative',
    paddingBottom: 50,
  },
  field: {
    display: 'block',
    width: '30%',
    paddingRight: 20,
  },
  fieldLong: {
    display: 'block',
    width: 'calc(60% + 20px)',
    paddingRight: 20,
  },
  button: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
})

const FormShowProfile = ({
  data: {
    regionId,
    email,
    managerId,
    middleName,
    name,
    phone,
    regNumber,
    role,
    surname,
    privilege,
    checkOldRegNumber,
    oldRegNumber,
    balance,
    type
  },
  regions,
  managers,
  roles,
  setIsEditable,
}) => {
  const classes = useStyles()

  const getManagerName = () => {
    const manager = managers.filter(el => el.id === managerId)
    if (manager.length) {
      const { surname: surnameM, name: nameM, middleName: middleNameM } = manager[0]
      return `${surnameM} ${nameM} ${middleNameM}`
    }
    return 'Отсутствует'
  }

  const getRoleName = () => roles && roles.filter(el => el.id === role)[0].description

  const showRegNumber = checkOldRegNumber && oldRegNumber

  return (
    <div className={classes.container}>
      <CustomTextField
        className={classes.fieldLong}
        label="ФИО"
        value={getFullName(surname, name, middleName)}
      />
      <div className={classes.wrapper}>
        <CustomTextField
          className={classes.field}
          label="Регистрационный номер"
          value={regNumber}
        />
        {regions && (
          <CustomTextField
            className={classes.field}
            label="Место проживания (Регион)"
            value={regionId && getById(regions, regionId).name}
          />
        )}
      </div>
      <div className={classes.wrapper}>
        <CustomTextField
          className={classes.field}
          label="Электронная почта"
          value={email}
        />
        <CustomTextField
          className={classes.field}
          label="Телефон"
          value={setPhoneMask(phone)}
        />
      </div>
      <div className={classes.wrapper}>
        <CustomTextField
          className={classes.field}
          label="Роль"
          value={getRoleName() || ''}
        />
        <CustomTextField
          className={classes.field}
          label="Менеджер по обучению"
          value={getManagerName()}
          multiline
        />
      </div>
      <div className={classes.wrapper}>
        {oldRegNumber && (
          <CustomTextField
            className={classes.field}
            label="Рег. номер в программе аттестации аудитора"
            value={oldRegNumber}
          />
        )}
        {balance !== null && (
          <CustomTextField
            value={String(balance)}
            label="Баланс"
            className={classes.field}
          />
        )}
      </div>
      <div className={classes.wrapper}>
        {privilege ? 'Является льготным клиентом' : 'Не является льготным клиентом'}
      </div>
      <Button
        className={classes.button}
        color="primary"
        onClick={() => setIsEditable(true)}
        variant="contained"
      >
        Редактировать
      </Button>
    </div>
  )
}

export default FormShowProfile
