import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import CustomTextField from 'common/MuiCustomTextField'

const useStyles = makeStyles({
  divider: {
    marginTop: 20,
    marginBottom: 20
  },
  university: {
    width: 586,
    marginRight: 20,
  },
  button: {
    position: 'absolute',
    right: 20,
    bottom: 20,
  },
})

const FormShowTrainings = ({ trainings, editable, setIsEditable }) => {
  const classes = useStyles()

  return (
    <>
      {!trainings.length && (<p>Сведения отсутствуют</p>)}
      {
        trainings.map(({
          id, centerId, other, center: { name }
        }, i) => (
            <div key={id}>
              <CustomTextField
                className={classes.university}
                label="Наименование УМЦ"
                value={centerId === 12 ? other : name}
                multiline
              />
              {i < trainings.length - 1 && (
                <Divider className={classes.divider} />
              )}
            </div>
          ))
      }
      {
        editable && (
          <Button
            color="primary"
            className={classes.button}
            onClick={() => setIsEditable(true)}
            variant="contained"
          >
            Редактировать
          </Button>
        )
      }
    </>
  )
}

export default FormShowTrainings
