import React from 'react'
import {
  Datagrid,
  NotFound,
  FunctionField,
  List,
  Loading,
  Pagination,
  TextField,
  usePermissions,
  useQueryWithStore,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import {
  Empty,
  FullName,
  PageTitle,
  Status,
  FormattedDateField,
  CustomShowButton
} from 'common'
import UsersFilter from 'components/users/UsersFilter'
import ActionButtons from '../components/users-list/ActionButtons'
import withPermission from '../common/withPermission'

import UsersMenu from '../components/users/UsersMenu'

const useStyles = makeStyles({
  row: {
    height: '60px'
  },
  wrap: {
    position: 'relative',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
})

const UsersPagination = props => <Pagination rowsPerPageOptions={[20, 50, 100]} {...props} />

const UsersList = props => {
  const classes = useStyles()
  const { permissions: { role } = {} } = usePermissions()

  const isManagerTraining = role === 'managerTraining'
  const isAdmin = role === 'admin' || role === 'manager'

  const { loaded, error, data: { id } = {} } = useQueryWithStore({
    type: 'getOne',
    resource: 'applicant/profile',
  })

  if (!(loaded && role)) return <Loading />
  if (error) return <NotFound />

  return (
    <div className={classes.wrap}>
      <PageTitle title="Пользователи" />
      <List
        {...props}
        actions={null}
        bulkActionButtons={false}
        empty={<Empty title="Пользователи отсутствуют" />}
        exporter={false}
        filter={isManagerTraining ? { managerId: id } : null}
        filters={<UsersFilter />}
        hasCreate
        pagination={<UsersPagination />}
        perPage={20}
        title=" "
      >
        <Datagrid classes={{ row: classes.row }}>
          <TextField
            source="applicant.regNumber"
            label="Рег. №"
            sortBy="regNumber"
          />
          <FunctionField
            label="ФИО"
            render={record => <FullName data={record} />}
            sortBy="surname"
          />
          <TextField
            source="email"
            label="Электронная почта"
            sortBy="email"
          />
          <FunctionField
            label="Зарегистрирован"
            sortBy="createdAt"
            render={({ createdAt }) => <FormattedDateField date={createdAt} formatting="dd MMMM yyyy" />}
          />
          <FunctionField
            label="Последняя активность"
            sortBy="updatedAt"
            render={({ updatedAt }) => updatedAt ? <FormattedDateField date={updatedAt} formatting="dd MMMM yyyy" /> : ''}
          />
          <FunctionField
            label="Статус пользователя"
            render={record => <Status status={record.status} />}
            sortBy="status"
          />
          <FunctionField
            label=""
            render={record => {
              if (!record.applicant) {
                return (<CustomShowButton {...record} />)
              } if (isAdmin || isManagerTraining) {
                return (<UsersMenu {...record} />)
              }
            }}
          />
        </Datagrid>
      </List>
      <ActionButtons isAdmin={isAdmin} />
    </div>
  )
}

export default withPermission(['admin', 'managerTraining', 'manager'])(UsersList)
