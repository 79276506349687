import React, { useState, useEffect } from 'react'

import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  useAuthenticated,
  ShowButton,
  usePermissions,
  useMutation,
  useRefresh,
  useNotify,
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { FullName, FormattedDateField } from 'common'

import ApplicationsFilter from 'components/applications/ApplicationsFilter'


const useStyles = makeStyles(() => ({
  header: {
    margin: '15px 0 24px 24px',
  },
  row: {
    height: '60px'
  },
  button: {
    margin: '20px 10px 20px 0px'
  }
}))

const Applications = props => {
  useAuthenticated()
  const [applicationsList, setApplicationsList] = useState([])
  const [activeButton, setActiveButton] = useState(false)
  const [mutate] = useMutation()
  const refresh = useRefresh()
  const notify = useNotify()

  const { permissions: { role } = {} } = usePermissions()
  const classes = useStyles()
  const isAdmin = role === 'admin' || role === 'manager'
  const isManager = role === 'managerTraining'

  const selectApplications = (e, application) => {
    const { checked } = e.target
    if (checked) {
      setApplicationsList([application, ...applicationsList])
    } else {
      const currentApplicationsList = applicationsList.filter(applicant => applicant.id !== application.id)
      setApplicationsList(currentApplicationsList)
    }
  }

  useEffect(() => {
    const uniqueValues = new Set(applicationsList.map(v => v.statusId))

    if (uniqueValues.size < applicationsList.length) {
      setActiveButton(true)
    } else {
      setActiveButton(false)
    }

  }, [applicationsList])

  const moveStatus = statusId => {
    mutate({
      type: 'update',
      resource: 'applications',
      payload: {
        id: applicationsList.map(application => application.id).join(),
        data: {
          statusId
        }
      }
    }, {
      onSuccess: () => {
        setApplicationsList([])
        refresh()
        notify('Данные сохранены')
      },
      onFailure: () => {
        notify('Ошибка', 'warning')
      }
    })
  }

  return (
    <>
      <Typography className={classes.header} variant="h3" gutterBottom>
        Заявки
      </Typography>
      <List
        title=" "
        {...props}
        bulkActionButtons={false}
        filters={isAdmin || isManager ? <ApplicationsFilter /> : null}
        exporter={false}
        actions={null}
      >
        <Datagrid classes={{ row: classes.row }}>
          {isAdmin && (
              <FunctionField
                  label=""
                  render={application =>
                      <Checkbox
                          color="primary"
                          onChange={e => {
                            selectApplications(e, application)
                          }}
                      />}
              />
          )}
          {(isAdmin || isManager) && (
            <TextField
              label="Рег. №"
              source="applicant.regNumber"
              sortable={false}
            />
          )}
          {(isAdmin || isManager) && (
            <FunctionField
              label="Претендент"
              render={record => <FullName data={record} />}
              sortable={false}
            />
          )}

          <TextField source="type.name" label="Наименование" />

          <FunctionField
              label="Описание"
              render={record => {
                if (record.typeId === 2) { // если это экзамен
                  const examDate = record?.group?.examDate
                  const city = record?.group?.city?.name
                  return (
                      <>
                        <div>{record.modules[0]?.name}</div>
                        {examDate && <FormattedDateField date={examDate} formatting="dd MMMM yyyy" />}
                        {city && <div>г. {city}</div>}
                      </>
                  )
                }
              }}
          />

          <FunctionField
              label="Статус"
              render={data => <span>{data.status.name}</span>}
              sortable={false}
              source="status.name"
          />

          <ShowButton
            icon={<InfoOutlinedIcon />}
            label=""
            disableRipple
          />
        </Datagrid>
      </List>
      {isAdmin && (
          <Grid container xs={9}>
            <Grid item xs={2} className={classes.button}>
              <Button
                  variant="outlined"
                  color="primary"
                  disabled={!activeButton}
                  onClick={() => moveStatus(3)}
                  fullWidth
              >
                Отклонить
              </Button>
            </Grid>
            <Grid item xs={2} className={classes.button}>
              <Button
                  variant="outlined"
                  color="primary"
                  disabled={!activeButton}
                  onClick={() => moveStatus(2)}
                  fullWidth
              >
                На оплату
              </Button>
            </Grid>
            <Grid item xs={2} className={classes.button}>
              <Button
                  variant="contained"
                  color="primary"
                  disabled={!activeButton}
                  onClick={() => moveStatus(4)}
                  fullWidth
              >
                Принять заявку
              </Button>
            </Grid>
          </Grid>
      )}
    </>
  )
}

export default Applications
