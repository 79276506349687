import React from 'react'
import {
  useQueryWithStore,
  Loading,
  NotFound,
  useAuthenticated,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ProfileMainInfoEditable from 'components/profile-editing/ProfileMainInfoEditable'
import StatusChip from 'components/profile/StatusChip'
import ProfileTrainings from 'components/profile/ProfileTrainings'
import ProfileEducations from 'components/profile/ProfileEducations'
import { PageTitle, PageSubtitle } from 'common'

import withPermission from '../common/withPermission'

const useStyles = makeStyles({
  subtitle: {
    padding: '24px 34px'
  },
  subtitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const ProfileEditingPage = ({ match: { params: { id } } }) => {
  const classes = useStyles()
  useAuthenticated()

  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: `applicant/${id}`
  })

  if (!loaded) { return <Loading /> }
  if (error) { return <NotFound /> }

  if (data) {
    const {
      regionId,
      educations,
      foreignEducation,
      foreignNumber,
      regNumber,
      status,
      trainings,
      privilege,
      checkOldRegNumber,
      oldRegNumber,
      balance,
      type,
      id: userId,
      user: {
        email,
        middleName,
        name,
        phone,
        role,
        surname,
        managerId
      }
    } = data

    return (
      <>
        <PageTitle title="Профиль претендента" />
        <ProfileMainInfoEditable
          regionId={regionId}
          email={email}
          id={userId}
          managerId={managerId}
          middleName={middleName}
          name={name}
          phone={phone}
          regNumber={regNumber}
          role={role}
          surname={surname}
          privilege={privilege}
          checkOldRegNumber={checkOldRegNumber}
          oldRegNumber={oldRegNumber}
          balance={balance}
          type={type}
        />
        <div className={classes.subtitleWrapper}>
          <PageSubtitle title="Анкета" className={classes.subtitle} />
          <StatusChip status={status} />
        </div>
        <ProfileEducations
          editable
          educations={educations}
          foreignEducation={foreignEducation}
          foreignNumber={foreignNumber}
          id={userId}
        />
        <ProfileTrainings trainings={trainings} id={userId} editable />
      </>
    )
  }
}

export default withPermission(['admin', 'managerTraining', 'manager'])(ProfileEditingPage)
