import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { MuiCustomTextField, PageSubtitle } from 'common'
import getFullName from 'utils/getFullName'
import PhoneEdit from 'components/profile/PhoneEdit'
import PasswordChange from 'components/profile/PasswordChange'

const useStyles = makeStyles({
  paper: {
    padding: 24
  },
  field: {
    display: 'block',
    width: 283,
    paddingRight: 20,
    '& > div': {
      overflow: 'hidden',
    }
  },
  fieldLong: {
    display: 'block',
    width: 586,
    paddingRight: 20,
  },
  wrap: {
    display: 'flex',
  },
  buttonWrap: {
    display: 'flex',
    marginBottom: '26px'
  },
  button: {
    marginTop: 20,
    height: 44,
    marginBottom: 14
  },
})

const UserInfoManager = ({
  email,
  middleName,
  name,
  phone,
  surname,
}) => {
  const classes = useStyles()
  const [passwordFormVisible, setPasswordFormVisible] = useState(false)

  return (
    <>
      <PageSubtitle title="Пользователь" />
      <Paper className={classes.paper}>
        <MuiCustomTextField
          className={classes.field}
          label="Логин"
          value={email}
        />
        <MuiCustomTextField
          className={classes.fieldLong}
          label="ФИО"
          value={getFullName(surname, name, middleName)}
        />
        <PhoneEdit phone={phone} />
        {passwordFormVisible ? (
          <PasswordChange cb={() => setPasswordFormVisible(false)} />
        ) : (
          <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => setPasswordFormVisible(true)}
            >
              Поменять пароль
            </Button>
        )}

      </Paper>
    </>
  )
}

export default UserInfoManager
