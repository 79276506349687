import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { makeStyles } from '@material-ui/core/styles'

import getFullName from 'utils/getFullName'

import MoreVertIcon from '@material-ui/icons/MoreVert'

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
})


const UserMenu = ({
  applicant: {
    id: applicantId,
    regNumber,
    user: {
      role
    }
  }
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon color="primary" />
        </Button>
        {anchorEl && (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <>
              {role === 'user' && (
                <MenuItem onClick={handleClose}>
                  <Link
                    className={classes.link}
                    to={{
                      pathname: `record-book/${applicantId}`,
                    }}
                  >
                    Зачетка
                  </Link>
                </MenuItem>
              )}
              <MenuItem onClick={handleClose}>
                <Link
                  className={classes.link}
                  to={{
                    pathname: `user/${applicantId}`,
                  }}
                >
                  Профиль
                </Link>
              </MenuItem>
              {role === 'user' && (
                <MenuItem onClick={handleClose}>
                  <Link
                    className={classes.link}
                    to={{
                      pathname: `applications?filter={"search":"${regNumber}"}`
                    }}
                  >
                    Список заявок
                  </Link>
                </MenuItem>
              )}
            </>
          </Menu>
        )}
      </>
    </>
  )
}

export default UserMenu
