import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import CustomTextField from 'common/MuiCustomTextField'
import getById from 'utils/getById'
import GetAppIcon from '@material-ui/icons/GetApp'

const useStyles = makeStyles({
  university: {
    width: 585,
    marginRight: 20
  },
  foreign: {
    width: 769
  },
  yearGraduation: {
    width: 162
  },
  diplomaInfo: {
    width: 283,
    marginRight: 20
  },
  divider: {
    marginTop: 20,
    marginBottom: 20
  },
  button: {

  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  fileLink: {
    display: 'flex',
    textDecoration: 'none'
  },
  linkText: {
    marginLeft: 5,
    marginBottom: 5,
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

const FormShowEducations = ({
  editable,
  educations,
  qualifications,
  setIsEditable
}) => {
  const classes = useStyles()

  const FileLink = ({ link }) => {
    const fileName = link.split('/').pop()

    return (
      <a href={link} className={classes.fileLink}>
        <GetAppIcon color="primary" />
        <Typography className={classes.linkText}>{fileName}</Typography>
      </a>
    )
  }

  return (
    <>
      {educations && educations.map(
        ({
          id, university, yearGraduation, diploma, numberRegistration, qualificationId, specializations, files,
          foreignNumber, foreignEducation
        }, i) => (
            <section key={id}>
              <div>
                <div className={classes.wrapper}>
                  <CustomTextField
                    value={university}
                    label="Наименование ВУЗа (как указано в дипломе)"
                    className={classes.university}
                    multiline
                  />
                  <CustomTextField
                    value={yearGraduation}
                    label="Год окончания"
                    className={classes.yearGraduation}
                  />
                </div>
                <div className={classes.wrapper}>
                  <CustomTextField
                    value={diploma}
                    label="Серия и номер диплома"
                    className={classes.diplomaInfo}
                  />
                  <CustomTextField
                    value={numberRegistration}
                    label="Регистрационный номер диплома"
                    className={classes.diplomaInfo}
                  />
                </div>
                <div className={classes.wrapper}>
                  {qualifications && (
                    <CustomTextField
                      value={getById(qualifications, qualificationId).name}
                      label="Квалификация"
                      className={classes.diplomaInfo}
                    />
                  )}
                  <CustomTextField
                    value={specializations}
                    label="Специальность"
                    className={classes.diplomaInfo}
                    multiline
                  />
                </div>
                {!!foreignEducation && (
                  <CustomTextField
                    value={foreignNumber}
                    label="№ свидетельства «Минобрнауки России» о признании иностранного документа об образовании"
                    className={classes.foreign}
                  />
                )}
              </div>
              {files.map(({ file }) => (
                <FileLink link={file} />
              ))}
              {i < educations.length - 1 && (
                <Divider className={classes.divider} />
              )}
            </section>
          )
      )}
      {editable && (
        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            className={classes.button}
            onClick={() => setIsEditable(true)}
            variant="contained"
          >
            Редактировать
          </Button>
        </div>
      )}
    </>
  )
}

export default FormShowEducations
