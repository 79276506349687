import React from 'react'
import {
  TextInput,
  SelectInput,
  required,
  ArrayInput,
  FormDataConsumer,
  FileInput,
  FileField,
  BooleanInput,
  maxLength
} from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import CustomSimpleFormIterator from 'common/CustomSimpleFormIterator'
import getArrayYears from '../../utils/getArrayYears'

const UploadPlaceholder = () => (
  <>
    <p>Кликните, чтобы загрузить файлы: скан документа об образовании; скан документа об иностранном образовании с переводом; в случае изменения фамилии — подтверждающий документ.</p>
    <p>Разрешены форматы .pdf, .jpeg, .png. Максимальный размер файла — 20мб.</p>
  </>
)

const useStyles = makeStyles({
  wrap: {
    marginTop: 25,
    marginBottom: 25,
  },
  layout: {
    overflowX: 'hidden'
  },
  booleanInput: {
    marginLeft: 14
  }
})

const ApplicantFormRasping = ({
  files,
  qualifications,
  setFiles,
  foreignEducation,
}) => {
  const classes = useStyles()

  const onChangeFileInput = (id, fileItems) => {
    const index = parseInt(id.substr(11, id.length - 12), 0)

    const filesWithIndex = fileItems.map(file => ({
      file,
      index
    }))

    setFiles([...files, ...filesWithIndex])
  }

  return (
    <>
      <div className={classes.layout}>
        <ArrayInput source="educations" label="">
          <CustomSimpleFormIterator buttonLabel="Добавить высшее образование">
            <FormDataConsumer>
              {({ getSource, id, scopedFormData }) => (
                <div className={classes.wrap}>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <BooleanInput className={classes.booleanInput} label="Высшее образование получено в другом государстве" source={getSource('foreignEducation')} />
                      {scopedFormData?.foreignEducation && (
                        <TextInput
                          disabled={!scopedFormData?.foreignEducation}
                          fullWidth
                          label="№ свидетельства «Минобрнауки России» о признании иностранного документа об образовании"
                          source={getSource('foreignNumber')}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextInput
                        fullWidth
                        label="Наименование ВУЗа (как указано в дипломе)"
                        source={getSource('university')}
                        validate={[required('Введите Наименование ВУЗа'), maxLength(4096, "Превышено максимальное количество знаков")]}
                        multiline

                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SelectInput
                        choices={getArrayYears()}
                        fullWidth
                        label="Год окончания"
                        source={getSource('yearGraduation')}
                        validate={required('Год окончания')}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <TextInput
                        fullWidth
                        label="Серия и номер диплома"
                        source={getSource('diploma')}
                        validate={required('Серия и номер диплома')}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextInput
                        fullWidth
                        label="Регистрационный номер диплома"
                        source={getSource('numberRegistration')}
                        validate={required('Регистрационный номер диплома')}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <SelectInput
                        choices={qualifications}
                        fullWidth
                        label="Квалификация"
                        source={getSource('qualificationId')}

                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextInput
                        fullWidth
                        label="Специальность"
                        source={getSource('specializations')}
                        validate={[required(), maxLength(4096, "Превышено максимальное количество символов")]}
                        multiline
                      />
                    </Grid>
                  </Grid>
                  <FileInput
                    accept="image/jpeg,image/png,application/pdf"
                    label=""
                    multiple
                    onChange={file => onChangeFileInput(id, file)}
                    placeholder={<UploadPlaceholder />}
                    source={getSource('files')}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                </div>
              )}
            </FormDataConsumer>
          </CustomSimpleFormIterator>
        </ArrayInput>
      </div>
    </>
  )
}

export default ApplicantFormRasping
