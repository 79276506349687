import React, { useState } from 'react'
import {
  Filter,
  ReferenceInput,
  TextInput,
  AutocompleteInput, SelectInput
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { DatePickerForFilter } from 'common'

const useStyles = makeStyles({
  searchInput: {
    width: 530,
  },
  paper: {
    marginBottom: 20,
    padding: '14px 20px 20px',
    width: '100%',
  },
  button: {
    marginTop: 20,
  },
  filter: {
    alignItems: 'flex-start',
    margin: 0
  }
})

const UsersFilter = props => {
  const classes = useStyles()
  const { setFilters } = props
  const [flagClearDate, setFlagClearDate] = useState()

  const onClickClear = () => {
    setFilters({})
    setFlagClearDate(Symbol(''))
  }

  return (
    <Paper className={classes.paper}>
      <Filter {...props} className={classes.filter}>
        <TextInput
          alwaysOn
          className={classes.searchInput}
          label="Поиск по ФИО, регистрационному номеру, e-mail пользователя"
          source="search"
          autoComplete="off"
        />
        <ReferenceInput
          alwaysOn
          label="Регион"
          reference="regions"
          source="regionId"
          filterToQuery={searchText => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <DatePickerForFilter
          alwaysOn
          source="createdAt"
          flagClearDate={flagClearDate}
          label="Дата регистрации"
        />
        <DatePickerForFilter
          alwaysOn
          source="dateFrom"
          flagClearDate={flagClearDate}
          label="От"
        />
        <DatePickerForFilter
          alwaysOn
          source="dateTo"
          flagClearDate={flagClearDate}
          label="До"
        />
      </Filter>
      <Button
        className={classes.button}
        color="primary"
        onClick={onClickClear}
        variant="contained"
      >
        Очистить фильтр
      </Button>
    </Paper>
  )
}

export default UsersFilter
