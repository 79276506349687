import React, { useState } from 'react'
import {
  FileField,
  FileInput,
  RadioButtonGroupInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useDataProvider
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import EditIcon from '@material-ui/icons/Edit'
import Paper from '@material-ui/core/Paper'
import { MuiCustomTextField as TextField } from 'common'

const useStyles = makeStyles({
  paper: {
    width: 'auto',
    padding: 24
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  imageWrapper: {
    width: '50%',
    height: 284,
    alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  infoWrapper: {
    width: '49%',
    marginRight: 20
  },
  city: {
    width: 228,
    display: 'flex'
  },
  place: {
    width: '100%'
  },
  address: {
    width: '100%'
  },
  room: {
    width: 200,
    marginRight: 20
  },
  img: {
    width: 'auto',
    height: '100%',
    display: 'block',
    objectFit: 'cover'
  },
  reportLink: {
    display: 'block',
    marginTop: 20
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent'
  },
  button: {
    marginRight: 20,
  }
})


const FormToolbar = ({
  classes,
  disabled,
  setIsEditable,
  ...props
}) => (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton
        handleSubmitWithRedirect={() => setIsEditable(true)}
        icon={<EditIcon />}
        label="Редактировать"
        className={classes.button}
      />
      <SaveButton disabled={disabled} label="Назначить экзамен" />
    </Toolbar>
  )

const LocationShow = ({ record, save, setIsEditable }) => {
  const classes = useStyles()
  const {
    place, address, room, image, dockLink, countPlaces, status, city
  } = record

  const [locationStatus, setLocationStatus] = useState(status)
  const [file, setFile] = useState(false)
  const dataProvider = useDataProvider()

  const choices = [
    { value: 'approve', text: 'Принятое' },
    { value: 'cancel', text: 'Отклоненное' }
  ]

  const saveFile = async () => {
    const response = await dataProvider.uploadFile('upload/file', { payload: file })
    const { data } = response
    return data
  }

  const saveData = async values => {
    let allFormValues = {
      ...values
    }

    if (file) {
      const dockLink = await saveFile()
      allFormValues = { ...allFormValues, dockLink }
    }

    save(allFormValues)
  }

  return (
    <SimpleForm redirect="show" toolbar={<FormToolbar classes={classes} disabled={locationStatus === 'cancel'} setIsEditable={setIsEditable} />} save={saveData}>
      <Paper className={classes.paper}>
        <div className={classes.wrapper}>
          <div className={classes.infoWrapper}>
            <TextField
              value={city.name}
              label="Город"
              className={classes.city}
            />
            <TextField
              value={place}
              label="Место проведения"
              className={classes.place}
              multiline
            />
            <TextField
              value={address}
              label="Адрес"
              className={classes.address}
            />
            <TextField
              value={room}
              label="Аудитория"
              className={classes.room}
            />
            <TextField
              value={countPlaces}
              label="Количество мест"
            />
          </div>
          <div className={classes.imageWrapper}>
            <img src={image} alt="Карта" className={classes.img} />
          </div>
        </div>
        <Divider />
        <div className={classes.wrapper}>
          <RadioButtonGroupInput
            resource="locations"
            source="status"
            choices={choices}
            optionText="text"
            optionValue="value"
            label="Статус помещения"
            defaultValue={status}
            row={false}
            margin="normal"
            onChange={value => setLocationStatus(value)}
          />
        </div>
        <Divider />
        <div className={classes.wrapper}>
          {dockLink ? (
            <Button
              variant="outlined"
              className={classes.reportLink}
              href={dockLink}
              color="primary"
            >
              Скачать отчет
            </Button>
          ) : (
              <FileInput
                source="dockLink"
                onChange={upload => setFile(upload)}
                label="Отчет"
                accept=".pdf,.doc,.docx,.xls,.xlsx"
                placeholder={<p>Нажмите, чтобы выбрать файл</p>}
                margin="normal"
              >
                <FileField source="src" title="title" />
              </FileInput>
            )}
        </div>
      </Paper>
    </SimpleForm>
  )
}

export default LocationShow
