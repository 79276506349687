import React from 'react'

import {
  BooleanInput
} from 'react-admin'

import Grid from '@material-ui/core/Grid'
import ApplicantFormInfo from './ApplicantFormInfo'
import ApplicantFormRasping from './ApplicantFormRasping'
import ApplicantAdditionallyRasping from './ApplicantAdditionallyRasping'

const ApplicantFormSteps = ({
  step,
  values: {
    checkOldRegNumber,
    foreignEducation
  },
  ...props
}) => {
  switch (step) {
    case 0:
      return <ApplicantFormInfo {...props} checkOldRegNumber={checkOldRegNumber} />
    case 1:
      return <ApplicantFormRasping {...props} foreignEducation={foreignEducation} />
    case 2:
      return (
        <>
          <ApplicantAdditionallyRasping />
          <Grid item xs={12}>
            <div style={{ marginTop: 10 }}>
              <BooleanInput label="Льготный пользователь" source="privilege" />
            </div>
            <div style={{ marginTop: 10 }}>
              <BooleanInput label="Я подтверждаю достоверность указанной при регистрации информации" source="conset" />
            </div>
            <div style={{ marginTop: 10 }}>
              <BooleanInput
                label={(
                  <div>
                    Я ознакомился с
                    {' '}
                    <a style={{ color: '#c62828' }} href=" https://eak-rus.ru/files/2019/prikaz_232n_141118.pdf" target="_blank">Порядком проведения сертификации ЕАК</a>
                    {' '}
                    и подтверждаю свое согласие на участие в экзамене в соответствии с указанным Порядком
                  </div>
                )}
                source="accept"
              />
            </div>
          </Grid>
        </>
      )
    default:
      return null
  }
}

export default ApplicantFormSteps
